<template>
  <div class="elite-container">
    <item-title :title="{name: '明亚精英', en_1: 'MINGYA', en_2: 'ELITE'}" />
    <div class="elite-content">
      <section class="cat-box">
        <div @click="orderFirstHandle(index, item.name)" v-for="(item, index) in position" :key="index" :class="[params.order_first == item.name ? 'active' : '', 'cat-item']">{{item.name}}</div>
      </section>
      <section class="search">
        <div class="left">
          <!-- <div v-if="isMobile" class="search-box">
            <input v-model="params.company" class="keyword" type="text" placeholder="请输入分公司名称">
            <div @click="searchHandle" class="search-btn"></div>
          </div>
          <input v-else v-model="params.company" class="company" type="text" placeholder="请输入分公司名称"> -->
          <el-select
          ref="agentSelect"
          @hook:mounted="cancalReadOnly"
          @visible-change="cancalReadOnly"
          clearable class="company-select" v-model="params.company" filterable remote placeholder="请输入分公司名称" :remote-method="remoteMethod" :loading="loading">
            <el-option style="font-size: .16rem;" v-for="item in companyOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select clearable v-model="params.order_second" placeholder="请选择职位">
            <el-option style="font-size: .16rem;" v-for="item in position[positionIndex].children" :key="item.name" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </div>
        <div class="split-vertical-line"></div>
        <div>
          <div class="right">
            <div class="search-box">
              <input v-model="params.name" @input="input" v-on:keyup.enter="searchHandle" class="keyword" type="text" placeholder="请输入会员姓名">
              <div @click="searchHandle" class="search-btn"></div>
            </div>
          </div>
        </div>
      </section>
      <div class="tip">排名不分先后</div>
      <div v-if="listData.length > 0" class="member-list">
        <div v-for="(item, index) in Math.ceil(listData.length / cols)" :key="index" class="row">
          <template v-for="(val, key) in cols">
            <div :key="key" class="item">
              <div v-if="listData[cols * index + key]" class="name">{{listData[cols * index + key].name}}</div>
              <div v-if="listData[cols * index + key]" class="address">{{listData[cols * index + key].company}}</div>
              <!-- <div v-if="listData[cols * index + key] && isSearch" class="address">{{listData[cols * index + key].order_first}} {{listData[cols * index + key].order_second}}</div> -->
              <div v-if="listData[cols * index + key] && isSearch" class="address">{{listData[cols * index + key].order_second}}</div>
            </div>
          </template>
        </div>
      </div>
      <pager class="pager" :total="total" :page="params.page" :limit="params.limit" @current-change="handleCurrentChange"></pager>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
import { getMemberListData, getComponyList } from '@/api/eliteStyle'
import { isMobile } from '@/helper/utils'
import Pager from '@/components/common/Pager.vue'
export default {
  components: {
    ItemTitle,
    Pager
  },
  data() {
    return {
      cols: isMobile() ? 2 : 4,
      isMobile: isMobile(),
      params: {
        order_first: '销售总监',
        order_second: '',
        name: '',
        company: '',
        page: 1,
        limit: 32
      },
      total: 0,
      positionIndex: 0,
      position: [
        {
          name: '销售总监',
          children: [
            { name: '全部职位' },
            { name: '资深销售总监' },
            { name: '高级销售总监' },
            { name: '销售总监' }
          ]
        },
        {
          name: '销售经理',
          children: [
            { name: '全部职位' },
            { name: '资深销售经理' },
            { name: '高级销售经理' },
            { name: '销售经理' }
          ]
        },
        {
          name: '合伙人',
          children: [
            { name: '全部合伙人' },
            { name: '资深合伙人' },
            { name: '高级合伙人' },
            { name: '合伙人' }
          ]
        }
      ],
      loading: false,
      listData: [],
      companyOptions: [],
      isSearch: false
    }
  },
  created() {
    this.getData()
  },
  watch:{
    $route(to, from){
      if (from.name != 'eliteDetail' && to.name == 'elite') {
        this.params = {
          order_first: '销售总监',
          order_second: '',
          name: '',
          company: '',
          page: 1,
          limit: 32
        }
        this.positionIndex = 0
        this.getData()
        // console.log(this.getNewsData)
      }      
    }
  },
  methods: {
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs
          if (Selects.agentSelect) {
            const input = Selects.agentSelect.$el.querySelector('.el-input__inner')
            input.removeAttribute('readonly')
          }
          if (Selects.agent2Select) {
            const appinput = Selects.appSelect.$el.querySelector('.el-input__inner')
            appinput.removeAttribute('readonly')
          }
          if (Selects.agent3Select) {
            const gameinput = Selects.gameSelect.$el.querySelector('.el-input__inner')
            gameinput.removeAttribute('readonly')
          }
        }
      })
    },
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        let res = await getComponyList({ company: query })
        // console.log(res)
        this.companyOptions = res.list
        this.loading = false;
      } else {
        this.companyOptions = [];
      }
    },
    async getCompanyList(company) {
      await getComponyList({ company: company })
      // console.log(res)
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.$emit('loading', false)
      this.getData()
    },
    async getData() {
      let params = JSON.parse(JSON.stringify(this.params))
      if (params.order_second == '全部合伙人' || params.order_second == '全部职位') {
        params.order_second = ''
      }
      const res = await getMemberListData(params)
      // console.log(res)
      this.total = res.total
      this.listData = res.records
      this.$emit('loading', false)
      if (this.params.name) {
        this.isSearch = true
      } else {
        this.isSearch = false
      }
    },
    input() {
      // if (!this.params.name) {
      //   this.isSearch = false
      // }
    },
    searchHandle() {
      this.isSearch = true
      this.getData()
    },
    orderFirstHandle(index, name) {
      this.positionIndex = index
      this.params.order_first = name
      this.params.order_second = ''
      this.params.name = ''
      this.getData()
    }
  }
}
</script>
<style lang="scss">
.elite-container {
  .el-select > .el-input {
    font-size: 0.16rem;
    height: 0.48rem !important;
    line-height: 0.48rem !important;
  }
  .el-input__inner {
    border: 0 none;
    font-size: 0.16rem;
    height: 0.48rem !important;
    line-height: 0.48rem;
    padding: 0 0.3rem;
  }
  .el-select-dropdown__item {
    padding: 0 0.3rem;
    font-size: 0.16rem !important;
    span {
      font-size: 0.16rem !important;
    }
  }
  .el-select-dropdown__item .el-input__inner {
    border: 0 none;
    font-size: 0.16rem;
    height: 0.48rem !important;
    line-height: 0.48rem;
    padding: 0 0.3rem;
  }
  .el-input__icon {
    line-height: 0.48rem;
    margin-right: 0.05rem;
  }
  .el-select {
    box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.1);
    width: 2.72rem;
    box-sizing: border-box;
    border-radius: 0.24rem;
    overflow: hidden;
    font-size: 0.16rem !important;
  }
}
</style>
<style lang="scss" scoped>
.elite-content {
  padding-top: 0.6rem;
  box-sizing: border-box;
  .search {
    margin-top: 0.4rem;
  }
  .member-list {
    margin-top: 0.3rem;
    border: 1px solid #eeeeee;
    width: 100%;
    .row {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      margin-left: 0;
      &:nth-child(odd) {
        background-color: #f7f7f7;
      }
      .item {
        padding: 0.2rem 0.3rem;
        position: relative;
        box-sizing: border-box;
        flex: 1;
        display: inline-block;
        // height: 0.9rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 0 .35rem;
        // border-right: 1px solid #EEEEEE;
        .name {
          font-size: 0.2rem;
        }
        .address {
          color: #999999;
          font-size: 0.14rem;
        }
        &::after {
          display: block;
          content: "";
          width: 1px;
          height: 0.4rem;
          background-color: #eeeeee;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
        &:last-child::after {
          display: none;
        }
      }
    }
  }
  .tip {
    font-size: 0.16rem;
    color: #cccccc;
    text-align: right;
    margin-top: 0.4rem;
  }
  .search {
    padding-left: 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .left {
      display: flex;
      align-items: center;
      .company-select {
        margin-right: 0.3rem;
        li {
          font-size: 0.16rem !important;
        }
      }
    }
    .right {
      display: flex;
      flex-wrap: wrap;
      .company {
        margin-right: 0.3rem;
        outline: none;
        border: 1px solid #ffffff;
        font-size: 0.16rem;
        width: 2.27rem;
        height: 0.48rem;
        border-radius: 0.24rem;
        box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.1);
        padding: 0 0.3rem;
        box-sizing: content-box;
        transition: all 0.3s;
        &:focus {
          border: 1px solid #fe6917;
          background: #ffffff;
        }
      }
    }
  }
  .search-box {
    width: 3.6rem;
    height: 0.48rem;
    display: flex;
    align-items: center;
    // box-shadow: 0px 0px .3rem 0px rgba(0, 0, 0, 0.1);
    .keyword {
      height: 100%;
      flex: 1;
      border: 0 none;
      box-sizing: border-box;
      padding: 0 0.3rem;
      font-size: 0.16rem;
      border: 1px solid #ffffff;
      transition: all 0.3s;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
      &:focus {
        border: 1px solid #fe6917;
        background: #ffffff;
      }
      border-radius: 0.24rem 0 0 0.24rem;
      outline: none;
      appearance: none;
      -webkit-appearance: none;
    }
    .search-btn {
      height: 100%;
      width: 0.8rem;
      border-radius: 0 0.4rem 0.4rem 0;
      background-image: url(../../assets/img/eliteStyle/search_btn_bg.png);
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .cat-box {
    padding-left: 0.2rem;
    display: flex;
    align-items: center;
    .cat-item {
      width: 1.5rem;
      height: 0.48rem;
      border-radius: 0.24rem;
      background-color: #f7f7f7;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #666666;
      font-size: 0.16rem;
      margin-right: 0.3rem;
      cursor: pointer;
      &.active {
        background-color: none;
        background-image: url(../../assets/img/eliteStyle/cat_small_active.png);
        background-size: 100% 100%;
        height: 0.52rem;
        color: #ffffff;
        padding-bottom: 0.04rem;
      }
    }
  }
}
@media only screen and (max-width: $moble_width) {
  .elite-container {
    .el-select {
      width: 100%;
    }
  }
  .elite-content {
    .search {
      .left {
        width: 100%;
      }
    }
    .tip {
      padding-left: 0.2rem;
      text-align: left;
    }
    .search-box {
      margin-top: 0.2rem;
      width: 100%;
    }
  }
}
</style>